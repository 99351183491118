import { useEffect } from 'react';

const Type5 = ({
  LIST,
  wepickData,
  isDisplay,
  //slideFunc,
  PBLogFuncBase,
  PItem,
  pb_gdsc_cd,
  pb_keyword,
  pb_source,
  CHANNEL_ID,
  priority,
}) => {
  const { WID, CONTENTS_TYPE } = wepickData;
  useEffect(() => {
    const count = LIST?.length || 0;
    if (isDisplay && count > 0) {
      if (CONTENTS_TYPE === 'PB') {
        const scrollFunc = (targetUl) => {
          $(targetUl)
            .find('li[data-wish_org_gd_no]')
            .each(function () {
              const idx = $(this).index();
              if (idx % 5 === 0) {
                const observeTop = $(window).scrollTop() + $(window).height();
                const target = $(this);
                const targetTop = target.offset().top + target.height() / 4;
                if (observeTop > targetTop) {
                  const targetLi = [];
                  for (let i = idx; i < idx + 5; i++) {
                    targetLi.push($(targetUl).find('li')[i]);
                  }
                  const gd_no_list = [];
                  targetLi.map((item) => {
                    const wish_org_gd_no = $(item).attr('data-wish_org_gd_no');
                    gd_no_list.push(wish_org_gd_no);
                    $(targetUl).find(`li[data-wish_org_gd_no=${wish_org_gd_no}]`).removeAttr('data-wish_org_gd_no');
                  });

                  if (gd_no_list.length > 0) {
                    Util.CallWishProductBoostLogApi(
                      'I',
                      CHANNEL_ID,
                      pb_gdsc_cd,
                      pb_keyword,
                      gd_no_list.toString(),
                      pb_source,
                    );
                  }
                }
              }
            });
        };

        window.addEventListener('scroll', function () {
          scrollFunc($(`#io_${priority}`).find('ul')[0]);
        });
        scrollFunc($(`#io_${priority}`).find('ul')[0]);
      }
    }
  }, []);
  return (
    <>
      <div className="main-cardlist" style={{ display: !isDisplay ? 'none' : '' }}>
        <div className="p-itemcard p-itemcard--grid5">
          <ul className="p-itemcard__gallery">
            {LIST.map((item, index) => {
              const bestSellerSticker =
                CONTENTS_TYPE === 'BS'
                  ? {
                      useSticker: true,
                      stickerText: (index + 1).toString().padStart(2, '0'),
                      stickerSize: 68,
                    }
                  : null;
              const PBLogFunc = (event) => {
                if (item.wish_org_gd_no) PBLogFuncBase(event, item.wish_org_gd_no, item.LINK_URL);
              };
              return (
                <PItem
                  item={item}
                  key={`${WID}_${index}`}
                  dispType={'main'}
                  liClass={'p-item'}
                  liAddData={{ 'data-wish_org_gd_no': item.wish_org_gd_no }}
                  {...bestSellerSticker}
                  imgSize={237}
                  onclickFunc={CONTENTS_TYPE === 'PB' ? PBLogFunc : null}
                  useRateNReviewCnt={GMKT.ServiceInfo.nation === Qoo10FE.Constants.nation.Qoo10 ? true : false}
                  useRemainCnt={false}
                  useRecommendCnt={false}
                  useShippingInfo={GMKT.ServiceInfo.nation === Qoo10FE.Constants.nation.Qoo10 ? false : true}
                  useGraph={CONTENTS_TYPE === 'GB'}
                  useLabel={false}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};
export default Type5;
