import { useEffect } from 'react';
const TypeS = ({
  LIST,
  wepickData,
  isDisplay,
  slideFunc,
  PBLogFuncBase,
  PItem,
  pb_gdsc_cd,
  pb_keyword,
  pb_source,
  CHANNEL_ID,
  priority,
}) => {
  const { WID, CONTENTS_TYPE } = wepickData;
  useEffect(() => {
    const count = LIST?.length || 0;
    if (isDisplay && count > 0) {
      if (CONTENTS_TYPE === 'PB') {
        const scrollFunc = (targetUl) => {
          const observeTop = $(window).scrollTop() + $(window).height();
          const target = $(`#io_${priority}`);
          const targetTop = target.offset().top + target.height() / 2;
          const targetLi = $(targetUl).find('li[data-wish_org_gd_no]');
          if (targetLi.length > 0 && observeTop > targetTop) {
            const gd_no_list = [];
            $(targetUl)
              .find('li')
              .map(function () {
                const wish_org_gd_no = $(this).attr('data-wish_org_gd_no');
                wish_org_gd_no && gd_no_list.push(wish_org_gd_no);
                $(`#dv_wepick_slide_${WID}`)
                  .find(`li[data-wish_org_gd_no=${wish_org_gd_no}]`)
                  .removeAttr('data-wish_org_gd_no');
              });

            if (gd_no_list.length > 0) {
              Util.CallWishProductBoostLogApi(
                'I',
                CHANNEL_ID,
                pb_gdsc_cd,
                pb_keyword,
                gd_no_list.toString(),
                pb_source,
              );
            }
          }
        };
        slideFunc(WID, count, (swiper) => {
          scrollFunc(swiper.slides[swiper.activeIndex]);
        });

        window.addEventListener('scroll', function () {
          scrollFunc($(`#dv_wepick_slide_${WID}`).find('ul')[0]);
        });
        scrollFunc($(`#dv_wepick_slide_${WID}`).find('ul')[0]);
      } else {
        slideFunc(WID, count);
      }
    }
  }, []);

  function splitIntoChunk(arr, chunk) {
    // 빈 배열 생성
    const result = [];

    for (let index = 0; index < arr.length; index += chunk) {
      let tempArray;
      // slice() 메서드를 사용하여 특정 길이만큼 배열을 분리함
      tempArray = arr.slice(index, index + chunk);
      // 빈 배열에 특정 길이만큼 분리된 배열을 추가
      result.push(tempArray);
    }

    return result;
  }

  const newList = splitIntoChunk(LIST, 5);

  return (
    <>
      <div className="main-cardlist" id={`dv_wepick_cardlist_${WID}`} style={{ display: !isDisplay ? 'none' : '' }}>
        <div className="slide-button slide-button--big">
          <button type="button" className="slide-button__prev" id={`btn_wepick_prev_${WID}`}>
            prev
          </button>
          <button type="button" className="slide-button__next" id={`btn_wepick_next_${WID}`}>
            next
          </button>
        </div>
        <div className="slide_control" id={`dv_wepick_slide_${WID}`}>
          <div className="p-itemcard p-itemcard--grid5 cont">
            {newList.map((ulitem, ulindex) => {
              return (
                <ul className="p-itemcard__gallery slide" key={`${WID}_${ulindex}`}>
                  {ulitem.map((item, index) => {
                    const bestSellerSticker =
                      CONTENTS_TYPE === 'BS'
                        ? {
                            useSticker: true,
                            stickerText: (ulindex * 5 + index + 1).toString().padStart(2, '0'),
                            stickerSize: 68,
                          }
                        : null;
                    const PBLogFunc = (event) => {
                      if (item.wish_org_gd_no) PBLogFuncBase(event, item.wish_org_gd_no, item.LINK_URL);
                    };
                    return (
                      <PItem
                        item={item}
                        key={`${WID}_${ulindex}_${index}`}
                        dispType={'main'}
                        liClass={'p-item'}
                        liAddData={{ 'data-wish_org_gd_no': item.wish_org_gd_no }}
                        {...bestSellerSticker}
                        imgSize={237}
                        onclickFunc={CONTENTS_TYPE === 'PB' ? PBLogFunc : null}
                        useRateNReviewCnt={GMKT.ServiceInfo.nation === Qoo10FE.Constants.nation.Qoo10 ? true : false}
                        useRemainCnt={false}
                        useRecommendCnt={false}
                        useShippingInfo={GMKT.ServiceInfo.nation === Qoo10FE.Constants.nation.Qoo10 ? false : true}
                        useGraph={CONTENTS_TYPE === 'GB'}
                        useLabel={false}
                      />
                    );
                  })}
                </ul>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default TypeS;
